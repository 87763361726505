<template>
    <div>
        <div class="directList">
            <div class="list" v-for="(item,index) in list" :key="index">
                <div class="listLeft">
                    <div class="listHead" v-if="type == 1">
                        <div class="pic" :style="{'border-color':item.friend_user.level_result?item.friend_user.level_result.color:'rgba(0,0,0,0)'}">
                            <img style="width: 80px;height: 80px;object-fit: cover;" :src="$imgurl + item.friend_user.avatar" alt="">
                        </div>
                        <div class="lable" v-if="item.friend_user.level_result" :style="{'background':item.friend_user.level_result.color}">
                            <img style="width: 25px;height: 25px;margin-right: 5px;" :src="$imgurl + item.friend_user.level_result.image" alt="">
                            <p :style="{'color':item.friend_user.level_result.font_color}">{{item.friend_user.level_result.title}}</p>
                        </div>
                    </div>
                    <div class="listHead" v-else-if="type == 4">
                        <div class="pic" :style="{'border-color':item.getUser.level_result?item.getUser.level_result.color:'rgba(0,0,0,0)'}">
                            <img style="width: 80px;height: 80px;object-fit: cover;" :src="$imgurl + item.getUser.avatar" alt="">
                        </div>
                        <div class="lable" v-if="item.getUser.level_result" :style="{'background':item.getUser.level_result.color}">
                            <img style="width: 25px;height: 25px;margin-right: 5px;" :src="$imgurl + item.getUser.level_result.image" alt="">
                            <p :style="{'color':item.getUser.level_result.font_color}">{{item.getUser.level_result.title}}</p>
                        </div>
                    </div>
                    <div class="listHead" v-else-if="type == 5">
                        <div class="pic" :style="{'border-color':item.fans.level_result?item.fans.level_result.color:'rgba(0,0,0,0)'}">
                            <img style="width: 80px;height: 80px;object-fit: cover;" :src="$imgurl + item.fans.avatar" alt="">
                        </div>
                        <div class="lable" v-if="item.fans.level_result" :style="{'background':item.fans.level_result.color}">
                            <img style="width: 25px;height: 25px;margin-right: 5px;" :src="$imgurl + item.fans.level_result.image" alt="">
                            <p :style="{'color':item.fans.level_result.font_color}">{{item.fans.level_result.title}}</p>
                        </div>
                    </div>
                    <div class="listHead" v-else>
                        <div class="pic" :style="{'border-color':item.user.level_result?item.user.level_result.color:'rgba(0,0,0,0)'}">
                            <img style="width: 80px;height: 80px;object-fit: cover;" :src="$imgurl + item.user.avatar" alt="">
                        </div>
                        <div class="lable" v-if="item.user.level_result" :style="{'background':item.user.level_result.color}">
                            <img style="width: 25px;height: 25px;margin-right: 5px;" :src="$imgurl + item.user.level_result.image" alt="">
                            <p :style="{'color':item.user.level_result.font_color}">{{item.user.level_result.title}}</p>
                        </div>
                    </div>
                    <div class="listInfo" v-if="type == 2">
                        <div class="listName">
                            <p class="name">{{item.getUser.nickname}}</p>
                            <div class="staBox" v-if="item.user.verification == 'normal'">
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/72.png" alt="">
                                <p>{{i18n.othor.online}}</p>
                            </div>
                            <div class="staBox nosta" v-else>
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/71.png" alt="">
                                <p>{{i18n.othor.Offline}}</p>
                            </div>
                        </div>
                        <p class="desc text">{{ item.getUser.bio }}</p>
                    </div>
                    <div class="listInfo" v-if="type == 3">
                        <div class="listName">
                            <p class="name">{{item.user.nickname}}</p>
                            <div class="staBox" v-if="item.user.verification == 'normal'">
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/72.png" alt="">
                                <p>{{i18n.othor.online}}</p>
                            </div>
                            <div class="staBox nosta" v-else>
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/71.png" alt="">
                                <p>{{i18n.othor.Offline}}</p>
                            </div>
                        </div>
                        <p class="desc text">{{ item.user.bio }}</p>
                    </div>
                    <div class="listInfo" v-if="type == 4">
                        <div class="listName">
                            <p class="name">{{item.getUser.nickname}}</p>
                            <div class="staBox" v-if="item.getUser.verification == 'normal'">
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/72.png" alt="">
                                <p>{{i18n.othor.online}}</p>
                            </div>
                            <div class="staBox nosta" v-else>
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/71.png" alt="">
                                <p>{{i18n.othor.Offline}}</p>
                            </div>
                        </div>
                        <p class="desc text">{{ item.getUser.bio }}</p>
                    </div>
                    <div class="listInfo" v-if="type == 5">
                        <div class="listName">
                            <p class="name">{{item.fans.nickname}}</p>
                            <div class="staBox" v-if="item.fans.verification == 'normal'">
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/72.png" alt="">
                                <p>{{i18n.othor.online}}</p>
                            </div>
                            <div class="staBox nosta" v-else>
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/71.png" alt="">
                                <p>{{i18n.othor.Offline}}</p>
                            </div>
                        </div>
                        <p class="desc text">{{ item.fans.bio }}</p>
                    </div>
                    <div class="listInfo" v-if="type == 1">
                        <div class="listName">
                            <p class="name text">{{item.friend_user.nickname}}</p>
                            <div class="staBox" v-if="item.friend_user.verification == 'normal'">
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/72.png" alt="">
                                <p>{{i18n.othor.online}}</p>
                            </div>
                            <div class="staBox nosta" v-else>
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/71.png" alt="">
                                <p>{{i18n.othor.Offline}}</p>
                            </div>
                        </div>
                        <div class="desc text" v-if="item.chat_message">
                            {{item.chat_message.type == 'text'?item.chat_message.content:item.chat_message.type == 'image'?`[${i18n.post.picture}]`:item.chat_message.type == 'video'?`[${i18n.post.video}]`:item.chat_message.type == 'music'?`[${i18n.post.yu}]`:item.chat_message.content}}
                        </div>
                        <!-- <p class="desc text" v-if="item.friend_user.bio">{{i18n.pop.profile}}:{{ item.friend_user.bio }}</p> -->
                    </div>
                    <!-- <div class="listInfo" v-else>
                        <div class="listName">
                            <p class="name">{{item.nickname}}</p>
                            <div class="staBox" v-if="item.verification == 'normal'">
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/72.png" alt="">
                                <p>{{i18n.othor.online}}</p>
                            </div>
                            <div class="staBox nosta" v-else>
                                <img style="width: 16px;height: 17px;margin-right: 5px;" src="../src/assets/icon/71.png" alt="">
                                <p>{{i18n.othor.Offline}}</p>
                            </div>
                        </div>
                        <p class="desc text">{{ item.bio }}</p>
                    </div> -->
                </div>
                <div class="listSet cursor" v-if="type == 1">
                    <div @click="chantBtn(item,index)" class="bgcolor" style="color: #FFFFFF;margin-right: 15px;">{{i18n.btn.chat}}
                        <p v-if="item.number>0">({{item.number}})</p>
                    </div>
                    <div style="background: #FBD5D5;margin-right: 15px;" @click="clearMessage(item,index)">{{i18n.btn.clear}}</div>
                    <div style="background: #FBD5D5;" @click="delPer(item,index)">{{i18n.btn.delete}}</div>
                </div>
                <div class="listSet cursor" v-else-if="type == 2 || type == 4 || type == 5">
                    <div @click="chantBtn(item,index)" class="bgcolor" style="color: #FFFFFF;">{{i18n.btn.chat}}</div>
                </div>
                <div class="listSet cursor" v-if="type == 3">
                    <div @click="chantBtn(item,index)" class="bgcolor" style="color: #FFFFFF;margin-right: 15px;">{{i18n.btn.chat}}</div>
                    <div @click="uncheck(item,index)" style="background: #FBD5D5;">{{i18n.btn.uncheck}}</div>
                </div>
            </div>
        </div>
        <div class="clearBox" v-if="clearDirect">
            <img @click="clearDirect = false" style="width: 25px;height: 25px;position: absolute;top: 24px;right: 24px;" src="../src/assets/icon/23.png" alt="">
            <p class="tit">{{i18n.pop.cleartext}}</p>
            <div class="clearSet">
            <div class="bgcolor" style="color: #fff;margin-right: 34px;" @click="clearBtn">{{i18n.btn.verify}}</div>
            <div style="background: #E4E4E4;" @click="clearDirect = false">{{i18n.btn.cancel}}</div>
            </div>
        </div>
        <!-- <el-dialog class="clearDirect" width="394px" :show-close="false" :visible.sync="clearDirect">
            
        </el-dialog> -->
        <pop ref="popOpen" :titName="titName" :type="popType"></pop>
    </div>
</template>
  
<script>
import { Bus } from '@/main';
import message from './message.vue';
export default {
  components: {message},
  props:{
    type:{
        type:Number,
        default:1
    },
    list:{
        type:Array,
        default:[]
    }
  },
  data() {
    //这里存放数据
    return {
        titName:'',
        popType:1,
        clearList:{},
        clearDirect:false, // 清空信息弹出框 
    };
  },
  //计算属性 类似于data概念
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 清空聊天记录弹出框
    clearMessage(e,i){
      this.clearDirect = true;
      this.clearList = e;
    },
    // 确认清空聊天记录
    clearBtn(){
        this.clearDirect = false;
        this.$postRequest("mini/userChatDel",{
            to_user:this.clearList.friend_id
        }).then((res) => {
            if(res.status == 200){
                this.$emit('updateFollow');
            }
        });
    },
    // 删除联系人
    delPer(e,i){
        this.$postRequest("Mini/delFriend",{
            friend_id:e.friend_id
        }).then((res) => {
            if(res.status == 200){
                this.list.splice(i,1);
                this.titName = res.message,
                this.$refs.popOpen.popDirect = true;
                setTimeout(()=>{
                    this.$refs.popOpen.popDirect = false;
                },1000)
            }else{
                this.titName = res.message,
                this.$refs.popOpen.popDirect = true;
                setTimeout(()=>{
                    this.$refs.popOpen.popDirect = false;
                },1000)
            }
        });
    },
    // 私聊
    chantBtn(item,index){
        let data = {
            item:item,
            type:this.type
        }
        this.list[index].number = 0;
        this.$emit('directUser',data)
    },
    //  取关
    uncheck(item,index){
        let data = {
            to_user_id:item.to_user_id
        }
        this.$postRequest("/user/addFollow",data).then((res) => {
            if(res.status == 200){
                this.$emit('updateFollow');
                Bus.$emit('updateUserinfo');
                this.titName = res.message,
                this.$refs.popOpen.popDirect = true;
                setTimeout(()=>{
                    this.$refs.popOpen.popDirect = false;
                },1000)
            }
        });
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>
<style lang="less" scoped>
 .directList{
    .list{
        width: 100%;
        height: 111px;
        border-radius: 5px 10px 10px 10px;
        background: #F4F4F4;
        padding: 12px 20px 12px 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .listLeft{
            display: flex;
            align-items: center;

        }
        .listHead{
            position: relative;
            width: 100px;
            flex-shrink: 0;
            .pic{
                width: 80px;
                height: 80px;
                border-radius: 53.5px;
                overflow: hidden;
                border: 2px solid transparent;
            }
            .lable{
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                padding: 0 5px;
                height: 25px;
                border-radius: 16px;
                background: #E9EAEB;
                box-shadow: 0px 0px 4px 0px rgba(215, 115, 241, 0.45);
                color: #9FA2A5;
                font-size: 12px;
                img{
                    width: 29px;
                    height: 29px;
                }
            }
        }
        .listInfo{
            margin-left: 12px;
            .listName{
                display: flex;
                align-items: center;
                .name{
                    font-size: 18px;
                    color: #333333;
                }
                }
                .desc{
                font-size: 14px;
                color: #333333;
                margin-top: 16px;
            }
            .staBox{
                margin-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                height: 25px;
                border-radius: 25px;
                background: rgba(255, 255, 255, 0.8);
                box-shadow: 0px 0px 4px 0px rgba(215, 115, 241, 0.45);
                color: #FF6FBC;
                font-size: 12px;
            }
            .nosta{
                background: #E92E2E;
                color: #FFFFFF;
                box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.45);
            }
        }   
        .listSet{
            display: flex;
            div{
                min-width: 50px;
                padding: 0 10px;
                height: 36px;
                border-radius: 36px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #E92E2E;
            }
            .setBtn{
                border-radius: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
                padding: 0;
                div{
                    margin: 0 !important;
                }
            }
        }
    }
}
.clearBox{
    width: 394px;
    height: 269px;
    border-radius: 10px;
    background: #FFFFFF;
    position: relative;
    padding-top: 94px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    .tit{
      font-size: 18px;
      color: #333333;
      text-align: center;
      display: block;
    }
    .clearSet{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      div{
        width: 120px;
        height: 45px;
        border-radius: 22.5px;
        color: #525252;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
}
</style>