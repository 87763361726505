<!-- 头部 -->
<template>
    <div class="headTop">
        <div class="topCon">
            <div class="logo">
              <img :src="$imgurl + logo" alt="">
            </div>
            <div class="navboxList">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                  text-color="#000000">
                    <el-menu-item index="1">{{ i18n.navigation.home }}</el-menu-item>
                    <el-menu-item index="2">{{ i18n.home.dynamic }}</el-menu-item>
                    <el-menu-item index="3">{{ i18n.navigation.gift }}</el-menu-item>
                    <el-menu-item index="4">{{ i18n.navigation.upgrades }}</el-menu-item>
                    <el-menu-item index="5">{{ i18n.navigation.topup }}</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
  components: {},
  props:{
    
  },
  data() {
    return {
      activeIndex:'1',
      config:[],
      logo:''
    };
  },
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  watch: {},
  //方法集合
  methods: {
    // 获取logo
    getConfig(){
        this.$postRequest("Common/getConfig").then((res) => {
            if(res.status == 200){
                this.config = res.data;
                this.logo = res.data[12].value
            }
        });
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  mounted() {
    this.getConfig();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>
<style lang="less" scoped>
  img{
    width: 100%;
  }
  .headTop{
    background-color: #fff;
    .topCon{
      width: 1200px;
      height: 80px; 
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo{
        width: 50px;
        height: 50px;
      }
      .navboxList{
        display: flex;
        align-items: center;
        position: relative;
        .el-menu{
          border: none;
          .el-menu-item{
            font-size: 15px;
            // min-width: 83px !important;
            height: 80px; 
            text-align: center;
            border-bottom: 6px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .is-active{
            font-size: 15px;
            color: #000000 !important;
          }
        }
      }
    }
  }
</style>