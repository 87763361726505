import api from '@/config/index' // 接口Api，图片地址等等配置，可根据自身情况引入，也可以直接在下面url填入你的 webSocket连接地址
import request from '@/config/index'
let $postRequest = request.post;
// let $getRequest = request.get;
import method from '@/config/api'
import { Bus } from '../main';
// WebSocket地址
const url = api.wssUrl;

// WebSocket实例
let ws

// 重连定时器实例
let reconnectTimer

// WebSocket重连开关WebSocket接收后端消息
let isReconnecting = false

// WebSocket对象
const websocket = {
  // WebSocket建立连接
  Init () {
    // 判断浏览器是否支持WebSocket
    if (!('WebSocket' in window)) {
      console.log('浏览器不支持WebSocket')
      return
    }
    // 创建WebSocket实例
    ws = new WebSocket(url)

    // 监听WebSocket连接
    ws.onopen = (e) => {
      console.log('WebSocket连接成功')
      console.log(e)
    }

    // 监听WebSocket连接错误信息
    ws.onerror = (e) => {
      console.log('WebSocket重连开关', isReconnecting)
      console.log('WebSocket数据传输发生错误', e)
      // 打开重连
      reconnect()
    }

    // 监听WebSocket接收消息
    ws.onmessage = (resource) => {
      console.log('WebSocket接收后端消息:', resource.data)
      let res = JSON.parse(resource.data);
      let data = res.data;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (res) {
        switch (res['type']) {
            // 服务端ping客户端
            case 'ping':
              websocket.Send({
                type: "ping"
              });
              break;
              //心跳回应
            case 'pong':
  
              break;
              // 登录 更新用户列表
            case 'init':
              localStorage.setItem('client_id',res.client_id);
              if(!userInfo){
                return
              }
              // 代码更改 -- 已更换域名
              $postRequest("/mini/bindUid",{
                client_id: res['client_id'],
                user_id: userInfo.user_id,
                }).then((res) => {
                  if(res.status == 200){
                    let numdata = {
                      type: "bindUid",
                      user_id: userInfo.user_id,
                      token: localStorage.getItem('token')
                    }
                    const msg = JSON.stringify(numdata)
                    // 发送消息给后端
                    websocket.Send(msg);
                  }
              });
              break;
              // 上线、下线通知
            case "isOnline":
              Bus.$emit('updateisOnline', {
                id: data.id,
                is_online: data.is_online
              })
              break;
              //异地登录
            case "offline":
              // let clientId = localStorage.getItem('client_id');
              // if (data.id == userInfo.user_id && data.client_id != clientId && data.isMobile) {
              //   method.message('您的账号在其他地方登录，已被迫下线！');
              //   Bus.$emit('goOut', res)
              //   //退出事件
              // }
              break;
            case 'is_read':
              Bus.$emit('is_read', res)
              break;
            case 'chat':
              // 群消息  ---------------
            case 'group':
              // 只要不是自己发的,才可以播放声音
                if (data.from_user != userInfo.user_id) {
                  // const contact = websocket.getContact(data.toContactId);
                  // const contact = websocket.getContact(data.from_user);

                  // console.log(contact,'-----');
                  // 如果开启了声音才播放
                  // if (data.toContactId == 'system' || contact.is_notice == 1) {
                  // 	//播放声音或震动
                  // }
                  Bus.$emit('appendMessage', res)
                }
              break;
            //更新联系人  --------------------------------
            case "setChatTop":
              Bus.$emit('updateContacts', {
                id: data.id,
                is_top: data.is_top
              })
              break;
            case "setIsNotice":
              Bus.$emit('updateContacts', {
                id: data.id,
                is_notice: data.is_notice
              });
              break;
            case "trends":
              Bus.$emit('updateMount')
              break;
            case "trends_comment":
              Bus.$emit('updateMount')
              break;
            case "follow":
              Bus.$emit('updateMount')
              break;
            case'system_message':
              Bus.$emit('updateMount')
              break;
              case "recharge":
              Bus.$emit('updateInfo')
              break;
        }
      }
      // 心跳消息不做处理
      if (resource.data === 'ok') {
        return
      }
      // 调用回调函数处理接收到的消息
      if (websocket.onMessageCallback) {
        websocket.onMessageCallback(resource.data)
      }
    }
  },
  // WebSocket连接关闭方法
  Close () {
    // 关闭断开重连机制
    isReconnecting = true
    ws.close()
    // ElMessage.error('WebSocket断开连接')
  },

  // WebSocket发送信息方法
  Send (data) {
    console.log('向服务器发送',data)
    // 处理发送数据JSON字符串
    const msg = JSON.stringify(data)
    // 发送消息给后端
    setTimeout(()=>{
      ws.send(msg)
    },10)
  },

  // 暴露WebSocket实例，其他地方调用就调用这个
  getWebSocket () {
    return ws
  },

  // 新增回调函数用于处理收到的消息
  onMessageCallback: null,

  // 设置消息处理回调函数
  setMessageCallback (callback) {
    this.onMessageCallback = callback
  },
  

  getContact(id) {
    let contacts = JSON.parse(localStorage.getItem('allContacts'));
    if (!id) {
      return contacts;
    } else {
      const contact = contacts.find(obj => obj.id == id);
      return contact;
    }
  }
}

// 监听窗口关闭事件，当窗口关闭时-每一个页面关闭都会触发-扩张需求业务
window.onbeforeunload = function () {
  // 在窗口关闭时关闭 WebSocket 连接
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let isOnline = {
    type: 'isOnline',
    user_id:userInfo.user_id,
    time: (new Date()).getTime(),
  }
  websocket.Send(isOnline);
  websocket.Close()
}

// 浏览器刷新重新连接
// 刷新页面后需要重连-并且是在登录之后
if (performance.getEntriesByType('navigation')[0].type === 'reload') {
  console.log('WebSocket浏览器刷新了')

  // 延迟一定时间再执行 WebSocket 初始化，确保页面完全加载后再进行连接
  // setTimeout(() => {
  //   console.log('WebSocket执行刷新后重连...')
  //   // 刷新后重连
  //   // 获取登录用户id
  //   let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  //   let userId = userInfo.user_id
  //   websocket.Init(userId)
  // }, 200) // 适当调整延迟时间
}

// 重连方法
function reconnect () {
  // 判断是否主动关闭连接
  if (isReconnecting) {
    return
  }
  // 重连定时器-每次WebSocket错误方法onerror触发它都会触发
  reconnectTimer && clearTimeout(reconnectTimer)
  reconnectTimer = setTimeout(function () {
    console.log('WebSocket执行断线重连...')
    // 获取登录用户id
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let userId = userInfo.user_id
    websocket.Init(userId)
    isReconnecting = false
  }, 4000)
}

// 暴露对象
export default websocket
