<!-- 首页 -->
<template>
  <div class="box">
    <el-container>
      <el-header height="80px">
        <div class="headTop">
          <div class="topCon">
              <div class="logo">
                <img :src="$imgurl + logo" alt="">
              </div>
              <div class="navBox">
                  <div class="nav">
                    <el-menu :default-active="activeIndex.toString()" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                      text-color="#000000" active-text-color="#FE64BA">
                        <el-menu-item index="1">
                          <router-link to="/home/index">{{ i18n.navigation.home }}</router-link>
                        </el-menu-item>
                        <el-menu-item index="2">
                          <router-link to="/post">{{ i18n.home.dynamic }}</router-link>
                        </el-menu-item>
                        <el-menu-item index="3">
                          <router-link to="/gift">{{ i18n.navigation.gift }}</router-link>
                        </el-menu-item>
                        <el-menu-item index="4">
                          <router-link to="/upgrades">{{ i18n.navigation.upgrades }}</router-link>
                        </el-menu-item>
                        <el-menu-item index="5">
                          <router-link to="/topup">{{ i18n.navigation.topup }}</router-link>
                        </el-menu-item>
                    </el-menu>
                  </div>
                  <div style="display: flex;align-items: center;">
                    <div class="cursor" @click="taglangfun">
                      <img style="width: 29px;height: 24px;margin-left: 30px;" src="../assets/icon/16.png" alt="">
                    </div>
                    <div class="login cursor" v-if="!loginSta">
                      <img src="../assets/mohead.png" alt="">
                      <p>{{ i18n.navigation.nologged }}</p>
                    </div>
                    <div style="position: relative;"  v-if="loginSta">
                      <el-dropdown @visible-change="dropdown">
                        <div class="headBox el-dropdown-link">
                          <img :src="$imgurl + userInfo.avatar" alt="">
                        </div>
                        <el-dropdown-menu class="dropdown-max" slot="dropdown" :append-to-body="false" ref="mydropd">
                          <el-dropdown-item v-for="(item,index) in setList" :key="index">
                            <div class="setBox"  @click="gosetPage(item,index)">
                              <img v-if="setNum == item.num" style="width: 19px;height: 19px;margin-right: 10px;" :src="item.selicon" alt="">
                              <img v-else style="width: 19px;height: 19px;margin-right: 10px;" :src="item.icon" alt="">
                              <p :class="setNum == item.num ? 'color':''">{{item.name }}</p>
                            </div>
                          </el-dropdown-item>
                          <div class="gooutBox cursor">
                            <div class="goOut" @click="goOut">
                              <img style="width: 19px;height: 19px;margin-right: 10px;" src="../assets/icon/5.png" alt="">  
                              <p>{{ i18n.navigation.logout }}</p>
                            </div>
                          </div>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div ref="here"></div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="banner" >
          <div class="searchBox cursor" v-if="bannerShow">
              <el-input v-model="input" :placeholder="i18n.navigation.input">
                <div slot="prepend" class="searchIcon">
                    <img src="../assets/icon/1.png" alt="">
                </div>
                <div slot="append" class="searchBtn bgcolor" @click="searchBtn">{{ i18n.navigation.search }}</div>
              </el-input>
          </div>
        </div>
        <div class="homeBox" style="width: 1200px;margin: 0 auto;display: flex;padding: 20px 0 30px">
          <div v-if="leftShow">
            <user-info></user-info>
          </div>
          <div class="content">
            <router-view></router-view>
          </div>
        </div>
        <foot-bottom></foot-bottom>
      </el-main>
    </el-container>
    <language ref="switchLanguage"></language>
  </div>
</template>

<script>
import { Bus } from '../main';
import headTop from '../../components/headTop.vue';
import footBottom from '../../components/footBottom.vue';
import userInfo from '../../components/userInfo.vue';
import language from '../../components/language.vue';
export default {
  name: "home",
  components: {headTop,footBottom,userInfo,language},
  data() {
    return {
      leftShow:true, // 左边用户展示隐藏
      bannerShow:true, // banner的搜索
      activeIndex:'1', // 导航菜单默认
      input:'', // 搜索框
      loginSta:true, //登录状态
      setNum:1, // 右上角头像选中
      userInfo:{},
      config:[],
      logo:''
    };
  },
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  watch: {
    $route(to, from) {
      // 根据路由的meta字段来控制导航栏的显示和隐藏
      this.leftShow = to.meta.showNav;
      this.bannerShow = to.meta.bannerSta;
      sessionStorage.setItem('showNav', to.meta.showNav);
    },
  },
  methods: {
    // 获取logo
    getConfig(){
        this.$postRequest("Common/getConfig").then((res) => {
            if(res.status == 200){
                this.config = res.data;
                this.logo = res.data[12].value
            }
        });
    },
    // 获取用户信息
    getUserinfo(){
        this.$postRequest("UserAuth/getUserInfo").then((res) => {
            this.userInfo = res.data;
            localStorage.setItem('userInfo',JSON.stringify(res.data));
        });
    },
    // 语言切换
    taglangfun() {
      this.$refs.switchLanguage.languagePop = true;
    },
    // 导航菜单点击
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key;
      sessionStorage.setItem('navNum', key);
    },
    // 右上方 头像点击 跳转界面
    gosetPage(item,index){
      this.setNum = item.num;
      if(item.num == 1){
        this.$method.skip(`/home/userHome?id=${this.userInfo.user_id}&type=4`)
      }else{
        this.$method.skip(item.router)
      }
      sessionStorage.setItem('rightsetNum', item.num);
    },
    // 右上方样式显示
    dropdown(){
      if(sessionStorage.getItem('rightsetNum')){
        this.setNum = sessionStorage.getItem('rightsetNum');
      }else{
        this.setNum = 1;
      }
    },
    // 测试 -- 退出登录
    goOut(){
      this.$postRequest("login/logout").then((res) => {
          console.log(res)
          if(res.status == 200){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            // let isOnline = {
            //   type: 'isOnline',
            //   user_id:userInfo.user_id,
            //   time: (new Date()).getTime(),
            // }
            // this.websocket.Send(isOnline);
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            this.$method.skip('/login')
            this.userInfo = {};
            this.websocket.Close()
          }
      });
    },
    // 点击搜索
    searchBtn(){
      Bus.$emit('searchBtn',this.input);
    }
  },
  created() {
    this.setList=[{
        num:1,
        router:'/home/userHome?type=4',
        name:this.i18n.navigation.homepage,
        icon:require('../assets/icon/68.png'),
        selicon:require('../assets/icon/2.png'),
    },{
        num:2,
        router:'/set',
        name:this.i18n.navigation.set,
        icon:require('../assets/icon/3.png'),
        selicon:require('../assets/icon/69.png'),
    },{
        num:3,
        router:'/set/emailinvitation',
        name:this.i18n.navigation.invite,
        icon:require('../assets/icon/4.png'),
        selicon:require('../assets/icon/70.png'),
    },]
  },
  beforeMount() {},
  mounted() {
    this.getConfig();
    // 获取左边信息操作栏是否存在 -- 刷新后
    this.leftShow = sessionStorage.getItem('showNav') === 'false' ? false : true;
    // 获取头部导航栏样式位置  -- 刷新后
    if(sessionStorage.getItem('navNum')){
      this.activeIndex = sessionStorage.getItem('navNum');
    }else{
      this.activeIndex = 1;
    }
    Bus.$on('updateInfo',()=>{
      this.getUserinfo();
    })
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {}, 
  destroyed() {}, 
  activated() {
    this.getUserinfo();
  }, 
  deactivated() {}, 
};
</script>
<style lang="less" scoped>
  img{
    width: 100%;
  }
  .el-header{
    padding: 0 !important;
    position: relative;
  }
  .headTop{
    position: fixed;
    top: 0; 
    width: 100%; 
    z-index: 1024;
    background-color: #fff;
    height: 80px;
    .topCon{
      width: 1200px;
      height: 80px; 
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo{
        width: 50px;
        height: 50px;
      }
      .navBox{
        display: flex;
        align-items: center;
        position: relative;
        .nav{
          .el-menu{
            border: none;
            .el-menu-item{
              font-size: 15px;
              // min-width: 83px;
              // width: auto !important;
              height: 80px; 
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
              border: none;
            }
            .is-active{
              font-size: 15px;
              background: linear-gradient(126deg, #FE64BA 30%, #D773F1 82%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              position: relative;
              &::after{
                content: "";
                width: 83px;
                height: 6px;
                border-radius: 18px;
                background: linear-gradient(95deg, #FE64BA 11%, #D773F1 85%);
                position: absolute;
                bottom: 0;
              }
            }
            a{
              display: block;
              min-width: 83px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 10px;
            }
          }
        }
        .login{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 26px;
          img{
            width: 30px;
            height: 30px;
          }
          p{
            font-size: 14px;
            color: #000000;
            margin-top: 6px;
          }
        }
        .headBox {
          cursor: pointer;
          width: 52px;
          height: 52px;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 60px;
          img{
            width: 52px;
            height: 52px;
            object-fit: cover;
          }
        }
        ::deep .popper__arrow{
          left: 100px !important;
        }
        .dropdown-max{
          min-width: 122px;
          height: 186px;
          border-radius: 5px;
          padding: 0;
          .el-dropdown-menu__item{
            padding: 0;
          }
          .setBox{
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            font-size: 15px;
            padding: 10px 0;
            color: #333333;
          }
          .gooutBox{
            display: flex;
            justify-content: center;
            margin-top: 10px;
            .goOut{
              width: 92px;
              height: 31px;
              border-radius: 16px;
              background: #FFDEDE;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FF0000;
              font-size: 12px;
            }
          }
        }
        .el-dropdown-link {
          cursor: pointer;
          color: #409EFF;
        }
        .el-icon-arrow-down {
          font-size: 15px;
        }
      }
    }
  }
  .banner{
    width: 100%;
    height: 250px;
    position: relative;
    background-image: url('../assets/banner.png');
    background-repeat: no-repeat;
    background-size: 100%;
    .searchBox{
      position: absolute;
      left: 50%;
      top: 170px;
      transform: translateX(-50%);
      .searchIcon{
        width: 20px;
        height: 20px;
      }
      .searchBtn{
        width: 80px;
        height: 50px;
        font-size: 15px;
        color: #FFFFFF;
        border-radius: 0px 25px 25px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ::v-deep .el-input{
        padding-left: 20px;
        width: 350px;
        height: 50px;
        border-radius: 25px;
        background: #FFFFFF;
        overflow: hidden;
        border: none;
        box-sizing: border-box;
        .el-input__inner{
          height: 50px;
          border: none;
          font-size: 15px;
          color: #333333;
        }
        .el-input-group__prepend{
          background-color: #fff !important;
          border: none;
          padding: 0 !important;
        }
        .el-input-group__append{
          border: none;
          padding: 0;
        }
      }
    }
  }
  .el-main{
    background: #F7F7F7;
    padding: 0 !important;
    .homeBox{
      .content{
        margin-left: 10px;
      }
    }
  }
  .el-footer{
    padding: 0 !important;
  }
</style>