module.exports = {
	message: {
		bottom: {
			language: "EN",
			about: "私たちについてです",
			agreement1: "ユーザープロトコルです",
			agreement2: "プライバシー協定です",
			secure: "安全です",
			help: "支援センターです",
			contact: "連絡してきます",
		},
		navigation: {
			language: "EN",
			home: "トップページ",
			post: "招待状です",
			gift: "贈り物です",
			upgrades: "アップグレードします",
			topup: "チャージします",
			homepage: "私のホームページです",
			set: "個人設定です",
			invite: "親友を招く",
			logout: "ログアウトします",
			nologged: "未登録です",
			search: "検索します",
			input: 'ユーザー名の入力をお願いします'
		},
		login: {
			welcome: "ご利用を歓迎します",
			introduce: "紹介します",
			welcomelogin: "ログイン歓迎です",
			nohave: "口座番号はまだですか?",
			register: "登録します",
			username: "メールアドレスまたはユーザー名の入力をお願いします",
			password: "パスワードをお願いします",
			remember: "パスワードを覚えます",
			forgot: "パスワードを忘れました",
			login: "登録します",
		},
		signin: {
			fillreset: "登録情報を記入します",
			upload: "プロフィール画像をアップロードします",
			information: "基本情報を埋めます",
			email: "メールボックスです",
			email2: "よく使うメールアドレスをお願いします",
			nickname: "ニックネームです",
			name: 'ニックネームの入力をお願いします(2 ~ 8文字まで)',
			setpassword: "パスワードを設定します",
			password: "パスワード(6 ~ 15文字、大文字と小文字を区別します)を入力してください",
			confirmpassword: "パスワードを確認します",
			nextpassword: "パスワードの再入力をお願いします",
			pwd: "パスワードが一致しません",
			back: "戻ります",
			laststep: "一歩進みます",
			next: "次のステップです",
			photo: "画像のアップロードをお願いします",
			sex: "性別です",
			Boy: "男子です",
			schoolgirl: "女子です",
			localarea: "所在する地域です",
			nation: "国です",
			province: "省です",
			city: "市です",
			oneself: "自己紹介をする",
			yourself: "自己紹介の入力をお願いします",
		},
		reset: {
			reset: "パスワードをリセットします",
			email: "メールボックスです",
			email2: "よく使うメールアドレスをお願いします",
			setpassword: "パスワードを設定します",
			password: "パスワード(6 ~ 15文字、大文字と小文字を区別します)を入力してください",
			confirmpassword: "パスワードを確認します",
			nextpassword: "パスワードの再入力をお願いします",
			OK: "確定です",
		},
		pop: {
			service: "お客様サービスです",
			send: "発送",
			inputtext: "文字を入力します",
			online: "オンライン",
			Offline: "オフライン",
			profile: "プロフィールです",
			cleartext: "チャットを空にしますか?",
			received: "いただいた「いいね」です",
			liked: "「いいね!」を押しました",
			lv: "レベルです",
			EXP: "経験値です",
			share: "分かち合います",
			forward: "より:転送します",
			website: "よります:ウェブサイト",
			picture: "画像です",
			photo: "アルバムです",
			dynamics: "TAの動きです",
			TRANSLATORS: "翻訳",
			forward2: "このスレッドの転送を確定しますか?",
			delete: "このスレッドの削除は確定しますか?",
			delete2: "画像の削除は確定ですか?",
			send2: "コメントを投稿します",
			from: "来ます",
			tag: "ラベルです",
			personal: "個人情報です",
			emote: "表情です",
			video: "ビデオです",
			TA: "TA",
			untranslate: "翻訳しません",
			text1: "有害な情報とはポルノ、暴力、広告その他あなたの日常生活に迷惑をかける内容です",
			text2: "您通報したい情報です:要举报的信息：",
			text3: "通報の理由を記入します:",
			text4: "ご安心ください,プライバシーは守りますので",
			fans: "私のファンです",
		},
		btn: {
			chat: "おしゃべりです",
			clear: "清空にします空",
			verify: "確認します",
			cancel: "取り消します",
			clearCon: "内容をクリアにします",
			prompt: "もう提示しません",
			uncheck: "関を取",
			close: "閉鎖します",
			report: "通報します",
			publish: "頒布します",
			add: "追加します",
			delete: "削除します",
			upload: 'アップロードします',
			submit: '提出します'
		},
		home: {
			vermicelli: "ファンです",
			dynamic: "動態",
			collect: "コレクションします",
			message: "私語です",
			visitor: "訪問記録です",
			service: "カスタマーに連絡します",
			system: "システムメッセージです",
			follow: "関心を持ちます",
			like: "いいですね",
			me: "@私です",
			sendPost: "招待状を送ります"
		},
		index: {
			all: "全部です",
			recommended: "推薦します",
			send: "TAを送ります",
			aite: "@TA",
			direct: "私語です",
		},
		post: {
			emote: "表情です",
			video: "ビデオです",
			picture: "画像です",
			ta: "TA",
			untranslate: "翻訳しません",
			yu:'音声です'
		},
		gift: {
			total: "プレゼントは全部です",
			mygift: "私の贈り物です",
			all: "全部です",
			details: "詳細です",
			inventory: "在庫です",
			specification: "スペックです",
			buy: "購入します",
			text: "お祝いの言葉を入力します",
			quantity: "数です",
			anonymous: "匿名で送ります",
			open: "公開贈呈です",
			private: "個人的に贈ります",
			desc: "プロフィール"
		},
		upgrades: {
			membership: "会員をアップグレードする",
			section: "一部の機能です",
			full: "全機能です",
			text: "あなたはもう永久会員です!",
			successfully: "昇格成功です~",
			text2: "金貨が足りませんのでチャージをお願いします~",
			text3: "アップグレード会員は確定ですか?",
			text4: "チャージします",
			permanent: "永久です",
		},
		topup: {
			coin: "チャージ金貨です",
			amount: "金額です",
			other: "その他の金額です",
			information: "チャージ情報です",
			text: "別の金額を入力願います",
			text2: "チャージ履歴/進呈履歴です",
			serial: "シリアル番号です",
			payee: "入金ユーザーです",
			recharge: "チャージ方式です",
			time: "時間です",
			status: "状態です",
		},
		setting: {
			information: "基本情報です",
			translation: "翻訳キットを購入します",
			privacy: "プライバシー設定です",
			notification: "お知らせします",
			blacklist: "ユーザー・ブラックリストです",
			security: "アカウントは安全です",
			invitation: "メールで招待します",
			invitation2: "リンク招待です",
			avatar: "アイコン",
			loginemail: "メールアドレスを登録します",
			areaname: "地域の名前です",
			addtag: "ラベルを付けます",
			unmodifiable: "修正不可です",
			package: "翻訳キットです",
			remaining: "残ります",
			character: "文字です",
			text1: "翻訳パッケージを選択します(文字) ",
			text2: "誰か私の投稿にコメントできますか?",
			text3: "誰か私の投稿に「いいね!」を押してもらえますか?",
			text4: "誰が私の招待状を収蔵することができます",
			text5: "全員(ブラックリスト利用者は含まれません)",
			text6: "私が注目している人です",
			text7: "システム通知メールを受信します:@、コメント、私信リマインダーを受信した場合、システムから通知メールを受信するかどうかおよび受信する週期を設定します",
			alluding: "私の話をしました",
			review: "レビューです",
			letter: "私信です",
			reminder: "メールによるリマインダーです",
			receive: "受信システムメールです",
			receive2: "システムメールは受信しません",
			never: "受け取りません",
			daily: "毎日受け取ります",
			weekly: "毎週受信します",
			monthly: "毎月受け取ります",
			shift: "持ち出します",
			text8: "ブラックリストに登録されたユーザーは、あなたをフォローしたり、コメントしたり、あなたのプライベートスペースを見ることができなくなります。あなたがすでにTAをフォローしていれば、自動的に関係を解消します",
			addblacklist: "ブラックリストを作成します",
			changepassword: "パスワードを変更します",
			bound: "メールボックスにバインディング済みです",
			original: "オリジナルパスワードです",
			newpwd: "新しいパスワードです",
			text9: "メールで友達を招待することができます。登録に成功すると、50ポイントの特典が与えられます",
			confirm: "ご招待を確認します",
			invitation3: "邀招待リストです请列表",
			text10: "メールをお願いします"
		},
		othor: {
			online: "オンライン",
			Offline: "オフライン",
			gift: '贈ります',
			giftHer: '贈ります',
			immediately: 'すぐに贈ります',
			Allread: 'すべて既読です',
			unread: '未読です',
			read: '既読です',
			text1: '何か新しいことがありますか~',
			input: '入力お願いします',
			trend: '動的詳細です',
			system: 'メッセージです',
			myname: '私のです',
			text: '私の金貨です',
			btnall: 'よく使う機能です',
			save: '保存します',
			mytrends: '私の動きです',
			mysave: '私のコレクションです',
			mylove: '私が注目しているのは',
			member: '会員です',
			record: 'チャージ記録です',
			Purchase: '購入履歴です',
			coin: '金貨を贈ります',
			Buynow: '即購入です',
			Invitefriends: '親友を招く',
			Copylink: 'リンクをコピーします',
			completed: '完成しました',
			Blacklist: 'ブラックリストです',
			text2: '金額をお願いします',
			text3: 'チャージ情報の充実をお願いします',
			text4: '審査待ちです',
			text5: '却下します',
			Liked: 'いいですね',
			published: '発表されました',
			my: '私です',
			my2: '私です',
			text6: 'リンクをコピーします',
			text7: '会員様がお待ちしています',
			text8: 'ギフトショップです',
			text9: '友達へのプレゼントを選びます',
			text10: '受け手です',
			mount: '総です',
			type: 'タイプです',
			money: 'チャージします',
			gomoney: 'すぐにチャージします',
			number: 'その他の数です',
			realname: '実名です',
			inputName: '実名をお願いします',
			realnum: '実際の携帯番号です',
			inputNum: '本物の携帯番号を入力してもらいます',
			realEmial: 'リアルメールです',
			inputEmial: 'メールの入力をお願いします',
			butText: '情報を買います',
			login1: '同じ世界に出会いました',
			and: 'とあります',
			you: 'あなたです',
			sigin: 'すぐに登録します',
			read2: '読んで同意しました',
			login2: '世界の反対側からです',
			sigintit: '登録します',
			login3: '知り合いです',
			login4: 'ここです',
			login5: 'アカウントを持っています',
			loginbtn: 'すぐにログインします',
			end: '完成です',
			update: '修正を確認します',
			memberdesc: '今のところはですね',
			userinfo: '個人情報です',
			userDesc: 'プロフィール入力お願いします~',
			chatTit: 'チャット中です',
			chatTitlist: 'チャットの記録です',
			tishi: '有効な内容を入力願います',
			sendText: '記録を贈ります',
			sendper: '贈呈者を入力します',
			buyText: '購入履歴でご覧いただけます',
			buySucc: '購入成功です',
			select: '選択します',
			selected: '選びました',
			page: '張',
			agreeBtn: '協議にチェックを入れます',
			senduser: 'ユーザーをプレゼントします',
			code: 'captcha入力をお願いします',
			getCode: 'captchaコードです',
			sosoText: 'リリースが成功したら審査を待ちます',
			succTrends: '転送成功です',
			total: '全部で',
			article: '',
			getmore: 'もっと調べます',
			realName: '実名です'
		},
		refund: {
			application: '返金の申し込みです',
			text: '安心して入力してください、私たちはあなたの財務資料を開示しません',
			region: '国や地域です',
			card: '返金口座番号です',
			name: '名前です',
			amount: '返金金額です',
			text1: '情報の補完をお願いします',
			text2: '払い戻し記録です',
			minMount:'払い戻し最小額です'
		}
	}
};